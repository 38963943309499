import { Component, OnInit, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { AppComponentClass } from "../../../service/AppComponent.class";
import { Edit, EditForm } from "../../model/edit.class";
import { Theme } from "../../../service/AutoTheme";
import { CareerEditService } from "../../../../careerdialer/careerdialer.services";
import { DialerService } from "../../dialer.service";
import { Email, EmailForm } from "../../model/email.class";
import { Option } from "../../../model/model.class";

@Component({
  selector: "dialer-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.css"],
})
export class EmailComponent extends AppComponentClass<Email, EmailForm> {
  private __data = new BehaviorSubject<Email>(null);
  public solution = this.__data.asObservable();
  protected emailstatus = new BehaviorSubject<boolean>(false);
  public emailview = this.emailstatus.asObservable();
  public form: FormGroup;
  public emailtemp = [];

  constructor(
    public data: DialerService,
    protected router: Router,
    protected fb: FormBuilder,
    protected msg: MatSnackBar,
    protected theme: Theme
  ) {
    super(data, router, fb, msg);
    // this.ssocket=new SocketService();
  }

  @Input() set emaildata(v: Email) {
    this.debug_log("Setting current data");
    this.__data.next(v);
  }
  ngOnInit() {
    this.solution.subscribe((res) => {
      console.log("Changed data on edit " + res);
      if (res == null) {
        this.cdata = new EmailForm(null);
        this.form = this.cdata.makeform(this.fb);
      } else {
        this.cdata = new EmailForm(res);
        this.form = this.cdata.makeform(this.fb);
        localStorage.setItem("email", this.cdata.email);
      }
      if (this.cdata.email) this.emailstatus.next(true);
    });

    this.theme.emailsolution.subscribe((res) => {
      console.log("email option");
      console.log(res);
      if (res && res != null && res.length > 0) {
        this.emailtemp = res;

        //alert(JSON.stringify(res));
      } else {
        //alert(res);
        this.emailtemp = [{ label: "No option", value: 0 }];
      }
    });
  }

  clickEmail(value: Option) {
    // alert(JSON.stringify(value));

    if (value) {
      this.theme.ChangeLead(-5);
      //alert(JSON.stringify(value.message));
      // console.log(value);
      let sdata = {
        id_lead: localStorage.getItem("LeadID"),
        id_template: value.value,
        to_email: this.cdata.email,
        message: value.message,
      };

      this.theme.SaveEmail(JSON.stringify(sdata));
      /* this.data.saveService(JSON.stringify(sdata), "saveemail").subscribe( rs =>  {
      console.log("Save Email data");
      console.log(rs);
      this.openSnackBar(rs.message,'');
    });  */
    } else {
      this.openSnackBar("No Email Option is available", "");
    }
  }

  warning() {
    if (this.cdata.email != this.form.get("email").value)
      alert("click on plain place of panel to save data");
  }

  changeEvnt(key: string) {
    console.log(key);
    if (key == "email") {
      this.cdata.email = this.form.get("email").value;
    }

    if (this.cdata.email) this.emailstatus.next(true);
    else this.emailstatus.next(false);

    localStorage.setItem("email", this.cdata.email);

    // update edit localstorage data
    var editFromLocalStorage = JSON.parse(localStorage.getItem("edit"));
    editFromLocalStorage.email = this.cdata.email;
    localStorage.setItem("edit", JSON.stringify(editFromLocalStorage));

    //Current lead
    let current = parseInt(localStorage.getItem("current_lead"));

    let search_lead = parseInt(localStorage.getItem("search_lead"));
    if (search_lead != 0) {
      current = search_lead;
    }
    console.log("current_lead: " + current);

    // update contact localstorage data
    var contactsFromLocalStorage = JSON.parse(localStorage.getItem("contacts"));
    contactsFromLocalStorage[current].edit = JSON.parse(
      localStorage.getItem("edit")
    );
    localStorage.setItem("contacts", JSON.stringify(contactsFromLocalStorage));
  }
}
