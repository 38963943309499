import {Component, ElementRef, ViewChild, AfterViewChecked, Input} from '@angular/core';
import { FormScripts,Scripts } from '../../model/script.class';
import { BehaviorSubject } from 'rxjs';
import { DialerForm } from '../../../../mortgage/model/model.class';
import { EmailDialogComponent } from '../../../../lead/emaildialog/email-dialog.component';
import { MatDialog } from '@angular/material';
import { MortgageService } from '../../../../mortgage/mortgage.service';
import { Theme } from '../../../service/AutoTheme';
import { DatePipe } from '@angular/common';



interface ListItem{
    image:string;
    name:string;
    description:string;
    time:string;
  }
interface SMSItem {
    profile: string;
    message: string;
    date: string;
    role:string;
    name:string;
  }

  export interface EmailTemplateElement {
    id:number;
    email: string;
    message: string;
  }


  const EMAIL_ELEMENT_DATA: EmailTemplateElement[] = [
    {  id: 1, email: 'mahesh@gmail.com', message: 'H'},
    { id: 2, email: 'vishal@gmail.com', message: 'He'},
    { id: 3, email: 'nitin@gmail.com',message: 'Li'},
    { id: 4, email: 'parimal@gmail.com', message: 'Li'},
  ];

@Component({
  selector: 'tab-section',
  templateUrl: './tab.component.html',
  styleUrls:['./tab.component.css'],
  providers: [DatePipe]
})
export class tabComponent {
  id: number;
  data: any;
  contacts: any[];
  comments: any[];
  sms: any[];
  userId: number;

  emailTemplateColumns: string[] = [ 'email' ,'message'];
  displayedColumns: string[] = ['email', 'message'];
  emailTemplateData = EMAIL_ELEMENT_DATA;

  script_name: string = "";
    cdata = null;
    showQueue = false;
    scriptmenu: Scripts[];

    private __data = new BehaviorSubject<Scripts>(null);
    public solution = this.__data.asObservable();
    public curscript: any;
    public curscripttitle: String;
    selectedTabIndex: number = 1;
    newCommentMessage: string = ''; 
    newSMS:string='';

    @ViewChild('commentsContainer') commentsContainer: ElementRef;

    ngAfterViewChecked() {
      this.scrollToBottom();
    }

    
    scrollToBottom(): void {
      try {
        if (this.commentsContainer && this.commentsContainer.nativeElement) {
          this.commentsContainer.nativeElement.scrollTop = this.commentsContainer.nativeElement.scrollHeight;
        }
      } catch(err) {
        console.error('Error scrolling to bottom:', err);
      }
    }
    
        constructor(public dialog: MatDialog,private mortgageService: MortgageService, private datePipe: DatePipe,protected theme: Theme,) {this.cdata = new DialerForm(null);}

    @Input() set scriptdata(v: Scripts) {
      this.__data.next(v);

      
  }

  ngOnInit() {
    this.mortgageService.getComments().subscribe(comments => {
      this.comments = comments; // Assuming comments is an array of objects
       //alert(JSON.stringify(this.comments));
    });

    this.mortgageService.getSMS().subscribe(sms => {
      this.sms = sms; // Assuming comments is an array of objects
       //alert(JSON.stringify(this.sms));
    });
    
    this.solution.subscribe(res => {
        if (res != null) {
            this.cdata = new FormScripts(res);
            this.curscript = res.script;
            this.curscripttitle = res.script_name;
        }
        else {
            console.log("NUll Script has been called");
            this.cdata = new FormScripts(res);
            this.curscript = null;
            this.curscripttitle = '';
        }
    });

    const userIdString = Number(localStorage.getItem("userid"));
    this.userId = userIdString ? +userIdString : null;
    //alert(JSON.stringify(userIdString));

    this.mortgageService.getEmail().subscribe(contacts => {
      this.contacts = contacts; // Assuming contacts is an array of objects
       //alert(JSON.stringify(this.contacts));
    });


}


formatTime(dateString: string): string {
  // Format the date to 'h:mm a' (e.g., '9:52 AM')
  return this.datePipe.transform(dateString, 'h:mm a');
}

  onTabClick(index: number) {
    this.selectedTabIndex = index;
  }
  openDialog( id:number) {
    
    const emailData = EMAIL_ELEMENT_DATA.find(item => item.id === id);
    console.log("emailData",emailData)
    const dialogRef = this.dialog.open(EmailDialogComponent,{
      data: emailData,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  submitSMS() {
    if (this.newSMS.trim()) {
      const newsms = {
        sender_id:localStorage.getItem("userid"),
        receive_id:"101",
        lead_id:localStorage.getItem("LeadID"),
        sms: this.newSMS,
      };
      this.mortgageService.addSMS(newsms).subscribe(response => {
        //console.log('Comment added successfully:', response);
        this.comments.push(response);  // Assuming response contains the new comment object
        this.newSMS = '';
        this.mortgageService.getSMS().subscribe(sms => {
          this.sms = sms; 
        });
      }, error => {
        console.error('Error adding comment:', error);
      });
    }
  }



  submitComment() {
    if (this.newCommentMessage.trim()) {
      const newComment = {
        user_id: localStorage.getItem("userid"),
        lead_id: localStorage.getItem("LeadID"),
        comments_message: this.newCommentMessage,
      };
      this.mortgageService.addComment(newComment).subscribe(response => {
        // Add the new comment to the comments array
        this.comments.push(response);  // Assuming response contains the new comment object
        this.newCommentMessage = '';
        // Fetch updated comments after adding a new one
        this.mortgageService.getComments().subscribe(comments => {
          this.comments = comments; 
        });
      }, error => {
        console.error('Error adding comment:', error);
      });
    }
  
}
}


