import { AppComponentClass } from '../../shared/service/AppComponent.class';
import { DispoData, FormDispoData } from '../model/model.class';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MortgageService, DispoDataService } from '../mortgage.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Option } from '../../shared/model/model.class';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})


export class AppointmentComponent extends AppComponentClass<DispoData, FormDispoData>  {

  public form:FormGroup;
  public dtstart:boolean = false;
  public dtend:boolean = false;
  public scriptlist:Option[];
 public scripts = [
    { id: 53, name: 'Mortgage Protection' },
    { id: 54, name: 'Final Expense' }
];

  constructor(public thisDialogRef: MatDialogRef<AppointmentComponent>, @Inject(MAT_DIALOG_DATA) public dialogdata: DispoData, public data: DispoDataService, protected router: Router, protected fb: FormBuilder,private mortgageService: MortgageService, protected msg: MatSnackBar)
  {
    super(data, router, fb , msg);
    //console.log(data);
  }



  ngOnInit() {
    this.cdata=new FormDispoData(this.dialogdata);
    this.form=this.cdata.makeform(this.fb);
    console.log(this.cdata);
    this.dtstart = false;
    this.dtend = false;

    this.data.getScriptOption().subscribe(res=>{
      console.log(res);
      this.scriptlist=res;
    });
  }

  onFocus()
  {
    this.dtstart=true;
  }

  onFocus1()
  {
    this.dtend=true;
  }


    onDDChange(stime:any)
    {
      console.log(stime);
      var tmp = new Date(stime.value);
      console.log(tmp);
      //this.edata.scheduleddate=edata.value;
      var mth="";
      var day="";
      if(tmp.getDate()<=9)
        day="0"+tmp.getDate();
      else
        day=tmp.getDate().toString();
      if(tmp.getMonth()<9)
        mth="0"+(tmp.getMonth()+1);
      else
        mth=(tmp.getMonth()+1).toString();
        
      // this.edata.startdate=tmp.getTime()/1000;
      // this.edata.enddate=(tmp.getTime()/1000)+86400;
      // this.edata.endhr=23;
      // this.edata.endmin=59;
      // this.edata.starthr=tmp.getHours();
      // this.edata.startmin=tmp.getMinutes();
      //this.cdata.date=tmp.getFullYear()+"-"+mth+"-"+day+" "+tmp.getHours()+":"+tmp.getMinutes();
      this.cdata.date=tmp.getFullYear()+"-"+mth+"-"+day;
      let starttime=this.transform(tmp.getHours()+":"+tmp.getMinutes());
      console.log(starttime);
      this.cdata.from_or_contact_time=starttime;
      
      let appt_duration=JSON.parse(localStorage.getItem('preferences')).appt_duration;
      console.log(appt_duration);
      var hourMinDur=appt_duration.split(':');
      var totalDur=(+hourMinDur[0] * (60000 * 60)) + (+hourMinDur[1] * 60000);
      console.log(totalDur);
      var enddate = new Date(tmp.getTime() + totalDur);
      console.log(enddate);
      let endtime=this.transform(enddate.getHours()+":"+enddate.getMinutes());
      this.cdata.to_or_agent_time=endtime;

      this.dtstart=false;
    }


    onDDChange1(etime:any)
    {
      console.log(etime);
      var tmp = new Date(etime.value);        
      //this.cdata.endate=tmp.getFullYear()+"-"+mth+"-"+day+" "+tmp.getHours()+":"+tmp.getMinutes();
      this.cdata.to_or_agent_time=tmp.getHours()+":"+tmp.getMinutes();
      this.dtend=false;
    }

    transform(time: any): any {
      let hour = (time.split(':'))[0]
      let min = (time.split(':'))[1]
      let part = hour > 12 ? 'PM' : 'AM';
      min = (min+'').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour+'').length == 1 ? `0${hour}` : hour;
      return `${hour}:${min} ${part}`
    }

    // onSubmit()
    // {
    //   this.thisDialogRef.close(this.cdata);
    // }



    onSubmit() {

      const selectedScriptId = this.form.get('id_script').value; // Get the selected script ID
    const selectedScript = this.scripts.find(script => script.id === selectedScriptId);
      const formdata = {
        id_agent:localStorage.getItem('userid'),
        id_lead:localStorage.getItem('LeadID'),
        id_script:this.form.value.id_script,
        date:this.cdata.date,
        from_or_contact_time:this.cdata.from_or_contact_time,
        to_or_agent_time:this.cdata.to_or_agent_time,
        disposition:localStorage.getItem('disposition'),
        current_did:localStorage.getItem('callerid'),
        place:this.form.value.place,
        comments:this.form.value.comments,
        script_name:selectedScript.name,

      }
      // alert(JSON.stringify(selectedScript));

      this.mortgageService.scheduleAppointment(formdata).subscribe(response => {
        this.snackBar.open('your  Appointment have scheduled', 'Close', { // {{ edit_3 }}
            duration: 2000,
        });
    });
    this.thisDialogRef.close();

  }

    onCancel()
    {
      this.thisDialogRef.close();
    }
}
