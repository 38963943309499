import { HttpClient}   from '@angular/common/http';
import { Observable }   from 'rxjs/Observable';
import { APIURL } from '../../app-routing.module';
import { Option } from '../model/model.class';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../../environments/environment';

export class AppService<T> {
    protected appmod : string;
    protected action : string;
    protected gdata : any;
    protected userkey : number;
    protected userid : number;
    private debug=environment.production;

    public service_data = new BehaviorSubject<T>(null);
    public solution= this.service_data.asObservable();

    public frm_label= new BehaviorSubject<string>("List Details");
    public label=this.frm_label.asObservable();
    public frm_status= new BehaviorSubject<boolean>(false);
    public status=this.frm_status.asObservable();
      
    constructor(protected http : HttpClient) { }
    
    public debug_log(log:string)
    {
      if(!this.debug)
        console.log(log);
    }

    public get Action() : string {
      return this.action
    }
    
    public set Action(v : string) {
      this.action = v;
    }
  
    public set Data(v : T) {
      this.debug_log("Setting current data");
      this.service_data.next(v);
    }

    changefrm(value:boolean)
    {
      this.frm_status.next(value);
    }

    changelabel(lbl:string)
    {
      this.frm_label.next(lbl);
    }    

    public getReport(data:any):Observable<T[]>{    
      return this.http.get<T[]>(APIURL+this.appmod, {headers:{"Content-Type": "application/json"}, params:{type:"main", filter: JSON.stringify(data)}});
    }

    public getOption(data:string):Observable<Option[]>{
      return this.http.get<Option[]>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{type:data}});
    }

    public getServiceParam(type?:string, filter?:any): Observable<T[]> {
      if(filter==undefined)
      {
        if(type!=undefined)
          return this.http.get<T[]>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{type}});
        else
          return this.http.get<T[]>(APIURL+this.appmod, {headers:{"Content-Type": "application/json"}, params:{type:"main"}});  
      }
      else
      {
        if(type!=undefined)
          return this.http.get<T[]>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{type, filter: filter}});
        else
          return this.http.get<T[]>(APIURL+this.appmod, {headers:{"Content-Type": "application/json"}, params:{type:"main", filter: filter}});
      }
    }

    public getOptionParams(url:any, filter:any):Observable<Option[]>{
      return this.http.get<Option[]>(APIURL+url,{headers:{"Content-Type": "application/json"}, params:{type:url, data:filter}});
    }
    
    public getDownload(data:string):Observable<Blob>{
      return this.http.get(APIURL+'download/'+this.appmod, {headers: {"Content-Type": "application/octet-stream"}, params: {type:"download", data:data}, responseType: 'blob', observe: 'body'});
    }

    
    public getService(type?:string, filter?:any, variables?:any): Observable<T[]> {
      if(filter==undefined || filter==null)
      {
        if(variables==undefined || variables==null)
        {
          if(type!=undefined)
            return this.http.get<T[]>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{type}});
          else
            return this.http.get<T[]>(APIURL+this.appmod, {headers:{"Content-Type": "application/json"}, params:{type:"main"}}); 
        }
        else
        {
          if(type!=undefined)
            return this.http.get<T[]>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{type,variables: JSON.stringify(variables)}});
          else
            return this.http.get<T[]>(APIURL+this.appmod, {headers:{"Content-Type": "application/json"}, params:{type:"main",variables: JSON.stringify(variables)}}); 
        }
      }
      else
      {
        if(type!=undefined || type !=null)
          return this.http.get<T[]>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{type, filter: JSON.stringify(filter)}});
        else
          return this.http.get<T[]>(APIURL+this.appmod, {headers:{"Content-Type": "application/json"}, params:{type:"main", filter: JSON.stringify(filter)}});
      }
    }


    public saveComments(data:any, type?:string): Observable<any>
    {

      if(type!=undefined)
        return this.http.post<any>(APIURL+this.appmod, data, { headers: {"Content-Type": "application/json"}, params:{type, authkey:localStorage.getItem('token')} });
      else
        return this.http.post<any>(APIURL+this.appmod, data, { headers: {"Content-Type": "application/json"}, params:{type:"main", authkey:localStorage.getItem('token')}});
    }
    
  
    public saveService(data:any, type?:string): Observable<any>
    {

      if(type!=undefined)
        return this.http.post<any>(APIURL+this.appmod, {"data":data}, { headers: {"Content-Type": "application/json"}, params:{type, authkey:localStorage.getItem('token')} });
      else
        return this.http.post<any>(APIURL+this.appmod, {"data":data}, { headers: {"Content-Type": "application/json"}, params:{type:"main", authkey:localStorage.getItem('token')}});
    }

    public detailService(key:number, type?:string):Observable<T>
    {
      if(type!=undefined)
        return this.http.get<T>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{id:key.toString(), type:type, authkey:localStorage.getItem('token')}});
      else
        return this.http.get<T>(APIURL+this.appmod,{headers:{"Content-Type": "application/json"}, params:{id:key.toString(), type:"detail", authkey:localStorage.getItem('token')}});
    }
  
    public statusService(data:any, key:number, type?:string): Observable<string>
    {
      if(type!=undefined)
        return this.http.post<string>(APIURL+this.appmod+key, {"data":data, "id":key}, { headers: {"Content-Type": "application/json"}, params:{id:key.toString(), type:type} });
      else
        return this.http.post<string>(APIURL+this.appmod+key, {"data":data, "id":key}, { headers: {"Content-Type": "application/json"}, params:{id:key.toString(), type:"status"} });
    }
  
    public updateService(data:any, key?:number, type?:string): Observable<T>
    {
      if(type!=undefined)
        return this.http.put<T>(APIURL+this.appmod, {"data":data}, { headers: {"Content-Type": "application/json"}, params: {id:key.toString(), type:type} });
      else
        return this.http.put<T>(APIURL+this.appmod, {"data":data}, { headers: {"Content-Type": "application/json"}, params: {id:key.toString(),type:"main"} });
    }

    public custupdateService(data:any, type?:string): Observable<T>
    {
      if(type!=undefined)
        return this.http.put<T>(APIURL+this.appmod, {"data":data}, { headers: {"Content-Type": "application/json"}, params: {type:type} });
      else
        return this.http.put<T>(APIURL+this.appmod, {"data":data}, { headers: {"Content-Type": "application/json"}, params: {type:"main"} });
    }

    public deleteService(key:number, type?:string): Observable<T>
    {
      if(type!=undefined)
        return this.http.delete<T>(APIURL+this.appmod, { headers: {"Content-Type": "application/json"}, params: {id:key.toString(), type:type}});
      else
        return this.http.delete<T>(APIURL+this.appmod, { headers: {"Content-Type": "application/json"}, params: {id:key.toString(),type:"main"}});
    }

    public PatchService(data:any, key:number, type?:string):Observable<T>{
      if(type!=undefined)
        return this.http.patch<T>(APIURL+this.appmod, {"data":data}, {headers:{"Content-Type": "application/json"}, params:{id:key.toString(), type:type}});
      else
        return this.http.patch<T>(APIURL+this.appmod, {"data":data}, {headers:{"Content-Type": "application/json"}, params:{id:key.toString(),type:"main"}});
    }

    public customGetService(url:string,filter?:any): Observable<any> {
      return this.http.get<any>(APIURL+url,{ headers: {"Content-Type": "application/json"}, params: {filter}});
    }

  }